<template>
 
  <app-loading-page
    :text="$t('general.loading')"
    :loading="loading"
    class="w-40 h-20 m-auto rounded-md shadow-md"
  />

  <div class="flex flex-col bg-white h-screen w-full">
    <div class="flex-none h-16">
      <div class="border-2 flex flex-row h-full">
        <div class="flex-none grid place-items-center w-20">
          <app-link :to="{ name: 'payment-open-link' }" class="text-black">
            <app-icon name="XIcon" class="w-5 cursor-pointer" />
          </app-link>
        </div>
        <div class="flex-1 border-l-2 flex items-center px-10"></div>
      </div>
    </div>
    <div class="flex-1">
      <CreatePage :edit_mode="true" />
    </div>
  </div>
</template>
<script>
import CreatePage from "@/views/pages/main/payment/open-payment-link/create-page";

export default {
  components: {
    CreatePage
  },

  beforeCreate() {
    this.$store.commit("openPaymentLinkStore/initOpenPaymentLink");
  },

  created() {
    this.$store.dispatch(
      "openPaymentLinkStore/findOpenPaymentLink",
      this.$route.params.id
    );
  },

  computed: {
    loading() {
      return this.$store.getters["openPaymentLinkStore/loading"];
    },
  }
};
</script>